<template>
    <!--   -->
    <div
        :class="{'fix_box': !showMask}"
        class="box">
        <div class="add_bg">
            <div class="header">
                <div class="txt">
                    <div class="des">
                        订单状态
                    </div>
                    <div
                        v-if="detailData.orderMixStatus"
                        class="status_txt">
                        {{ utils.checkOrderStatus(detailData) }}
                    </div>
                </div>
            </div>
            <div
                v-show=" detailData.shippingInformation&& detailData.shippingInformation.address"
                class="addressee">
                <div class="namephone">
                    <span class="name">{{ detailData.shippingInformation&&detailData.shippingInformation.name }}</span>
                    <span class="phone">{{ detailData.shippingInformation&& detailData.shippingInformation.tel }}</span>
                </div>
                <div class="address">
                    <div class="address_txt">
                        {{ detailData.shippingInformation&& (detailData.shippingInformation.location +detailData.shippingInformation.address) }}
                    </div>
                </div>
                <img
                    :src="require('@img/order/group.jpg')"
                    class="group"
                    alt="">
            </div>

            <div class="card">
                <CommonTitle :title="detailData.product&&detailData.product.length&&detailData.product[0].merchantName" />
                <div class="drug_list">
                    <DrugItem
                        :data="drugData"
                        v-for="drugData in detailData.product">
                        <template v-slot:numOrCount>
                            <p class="num">
                                x{{ drugData.count }}
                            </p>
                        </template>
                    </DrugItem>
                    <div class="cost">
                        <div class="cost_info van-hairline--bottom">
                            <div>
                                <span class="distribution"> 商品金额:
                                </span><span class="sum_money">￥{{ changeFixed(detailData.amount) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="common_info">
                <CommonTitle title="订单信息" />
                <div class="info_list">
                    <div class="info_item">
                        <div class="mr_40">
                            订单号 :
                        </div>
                        <div>{{ detailData.mainOrderNo }}</div>
                    </div>
                    <div class="info_item">
                        <div class="mr_30">
                            订单时间 :
                        </div>
                        <div> {{ detailData.dateCreated }}</div>
                    </div>
                    <div class="info_item">
                        <div class="mr_30">
                            支付方式 :
                        </div>
                        <div>{{ detailData.paymentMethod || '--' }}</div>
                    </div>
                    <div class="info_item">
                        <div class="mr_30">
                            商品金额 :
                        </div>
                        <div>{{ Number(detailData.amount).toFixed(2) }}元</div>
                    </div>
                </div>
                <div class="cost pad_18">
                    <div class="cost_info van-hairline--top">
                        <div>
                            <span class="distribution"> 实付款:
                            </span><span class="sum_money">￥{{ changeFixed(detailData.actualPayment/100) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="foot_btn">
            <button
                :disabled="isDisabled"
                @click="paySign(detailData)"
                :class="{'gray': isDisabled}"
                class="pay_btn">
                {{ isDisabled ? '已签收' : '签收' }}
            </button>
        </div>

        <!-- 遮罩层 -->
        <div
            v-show="showMask"
            class="mask">
            <div class="bg" />
            <div class="parent">
                <Core
                    @closeDialog="handleDialogClose"
                    @handleSubmit="submitScore" />
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/components/core';
import CommonTitle from '@/components/order/CommonTitle';
import DrugItem from '@/components/order/drug-item';
import api from '@/api';
import { Toast } from 'vant';
import buryingPoint from '@/mixins/buryingPoint';

import {
    checkOrderStatus,
} from '@/utils';

export default {
    components: {
        Core,
        CommonTitle,
        DrugItem,
    },
    mixins: [buryingPoint],
    data() {
        return {
            showMask: false,
            detailData: {},
            isDisabled: false,
            utils: {
                checkOrderStatus,
            },
            count: 0,
        };
    },
    created() {
        console.log(this.eventId, '---- evnet id ----');

        this.getInit();
        this.getOrderStatus();
    },
    methods: {
        getInit() {
            //
            api.getQueryByDetail({ mainOrderNo: this.$route.params.mainOrderNo })
                .then((res) => {
                    this.$nextTick(() => {
                        this.detailData = res.data;
                    });
                });
        },
        getOrderStatus() {
            api.getQueryByOrderNo({ orderCenterNo: this.$route.params.mainOrderNo })
                .then((res) => {
                    this.$nextTick(() => {
                        if (res.data) {
                            this.isDisabled = true;
                        }
                    });
                });
        },
        changeFixed(data) {
            return data ? Number(data).toFixed(2) : '0.00';
        },
        paySign() {
            this.showMask = true;
            // console.log(this.eventId, '---- event id from burying point -----');
            this.eventId = 'YFP00001B0001';
        },
        handleDialogClose() {
            this.showMask = false;
            this.eventId = 'YFP00001B0003';
        },
        submitScore(obj) {
            this.count += 1;
            console.log(this.count);
            let param = {};
            param = obj;
            param.orderComment.orderCenterNo = this.$route.params.mainOrderNo;
            param.count = this.count;
            api.addScoreInfo(param)
                .then((res) => {
                    if (res.code === '0') {
                        this.showMask = false;
                        this.getOrderStatus();
                        console.log(param, ']====param');
                    } else {
                        // param.count = 0;
                        // this.count = 0;
                        Toast.fail(res.message);
                    }
                });

            this.eventId = 'YFP00001B0002';
        },
    },
};
</script>
<style lang="scss" scoped>
.fix_box {
    // position: fixed;
    // padding-bottom: 80px;
    height: calc(100vh - 84px);
}

.box {
    width: 100%;
    background: #fff;
    overflow: scroll;
    .add_bg {
        background: #f5f7fa;
    }
    .header {
        height: 80px;
        background: url('~@img/order/bg.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 18px;
        color: white;
        margin-bottom: 8px;
        .txt {
            font-weight: 500;
            .des {
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                margin: 13px 0 2px;
            }
            .status_txt {
                height: 33px;
                line-height: 33px;
                font-size: 24px;
            }
        }
    }
    .addressee {
        background: #fff;
        font-size: 16px;
        position: relative;
        margin-bottom: 8px;
        padding-bottom: 18px;
        .namephone {
            height: 22px;
            line-height: 22px;
            padding: 18px 18px 8px;
            font-weight: 600;
            color: #333;
            .name {
                margin-right: 16px;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    top: 2px;
                    background: url('~@img/phone.png') no-repeat;
                    background-size: 16px 16px;
                    margin-right: 8px;
                }
            }
        }
        .address {
            display: flex;
            padding: 0  18px;
            .address_txt {
                color: #333;
                font-weight: 400;
                line-height: 22px;
                width: 287px;
                word-wrap: break-word;
                word-break: break-all;
                white-space: pre-wrap;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                position: relative;
                top: 2px;
                background: url('~@img/icon_location.png') no-repeat;
                background-size: 16px 16px;
                margin-right: 8px;
            }
        }
        .group {
            width: 100%;
            height: 4px;
            position: absolute;
            bottom: 0;
        }
    }
    .card {
        background: #fff;
        margin-bottom: 8px;
        .drug_list {
            padding: 0 18px;
            .num {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #666;
                line-height: 20px;
            }
        }
    }
    .cost {
        height: 49px;
        line-height: 49px;
        .cost_info {
            text-align: right;
            .distribution {
                font-size: 14px;
                font-weight: 400;
                color: #62636c;
            }
            .sum_money {
                font-size: 16px;
                font-weight: 500;
                color: #e94947;
            }
        }
    }
    .pad_18 {
        padding: 0 18px;
    }

    .common_info {
        margin-bottom: 8px;
        background: white;
        .info_list {
            padding: 18px 18px 10px;
            .info_item {
                line-height: 20px;
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: 400;
                color: #62636c;
                display: flex;
                .mr_40 {
                    margin-right: 44px;
                    white-space: nowrap;
                }
                .mr_30 {
                    margin-right: 30px;
                    white-space: nowrap;
                }
            }
        }
    }

    .foot_btn {
        height: 52px;
        line-height: 52px;
        background: #fff;
        text-align: right;
        padding-right: 18px;
        box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
        position: fixed;
        bottom: constant(safe-area-inset-bottom);
        bottom: env(safe-area-inset-bottom);
        left: 0;
        right: 0;
        .pay_btn {
            width: 80px;
            height: 30px;
            padding: 5px 0;
            border-radius: 15px;
            border: 1px solid #f36218;
            font-weight: 500;
            font-size: 14px;
            margin-left: 8px;
            color: #fff;
            background: #f36218;
        }
        .gray {
            border: none;
            color: #fff;
            background: #ccc;
        }
    }

    /deep/ .van-overlay {
        top: auto;
        bottom: constant(safe-area-inset-bottom);
        bottom: env(safe-area-inset-bottom);
        bottom: 50px;
        margin-bottom: 16px;
    }

    /deep/ .van-popup--bottom {
        bottom: constant(safe-area-inset-bottom);
        bottom: env(safe-area-inset-bottom);
        bottom: 50px;
        margin-bottom: 16px;
    }
}
.mask {
    position: relative;
    .bg {
        position: fixed;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 1;
        -moz-opacity: 0.7;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }
    .parent {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }
}
</style>
