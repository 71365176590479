/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */

// 创建一个浏览器的唯一ID
export const createUUID = function () {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
  return uuid;
};

// 避免计算性能开销，合理利用缓存存储浏览器ID
export const readUUID = function () {
  let uuid = sessionStorage.getItem('uuid');

  if (!uuid) {
    uuid = createUUID();
    sessionStorage.setItem('uuid', uuid);
  }
  return uuid;
};

// 判断是否是wx环境
export const isWX = function () {
  const ua = window.navigator.userAgent.toLowerCase();
  const n = ua.match(/MicroMessenger/i);
  if (n && n.includes('micromessenger')) {
    return true;
  }
  return false;
};

// 获取网络类型
export const getNetworkType = function () {
  if (isWX()) {
    const ua = window.navigator.userAgent;
    let networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
    networkStr = networkStr.toLowerCase().replace('nettype/', '');
    return networkStr;
  }

  if (navigator?.connection?.type) {
    const networkStr = navigator.connection.type;
    return networkStr;
  }

  return 'unknown';
};
