<template>
  <div :class="{ fix_box: showMask || showPrescription }" class="box">
    <div class="header">
      <div class="txt">
        <div class="des">订单状态</div>
        <div v-if="detailData.orderMixStatus" class="status_txt">
          {{ utils.checkOrderStatus(detailData) }}
        </div>
      </div>
      <div v-show="showTime" class="time">
        <img :src="require('@img/order/time.png')" class="time_img" alt="" />
        <p class="time_txt">
          <CountDown :time="time" format="mm:ss" @finish="finish" />
        </p>
      </div>
    </div>
    <div class="addressee">
      <div class="namephone">
        <span class="name">{{
          detailData.shippingInformation && detailData.shippingInformation.name
        }}</span>
        <span class="phone">{{
          detailData.shippingInformation && detailData.shippingInformation.tel
        }}</span>
      </div>
      <div class="address">
        <div v-if="detailData.origin > 5" class="address_txt">
          {{
            detailData.shippingInformation &&
            detailData.shippingInformation.address
          }}
        </div>
        <div v-else class="address_txt">
          {{
            detailData.shippingInformation &&
            detailData.shippingInformation.location
          }}{{
            detailData.shippingInformation &&
            detailData.shippingInformation.address
          }}
        </div>
      </div>
      <img :src="require('@img/order/group.jpg')" class="group" alt="" />
    </div>

    <div class="card">
      <CommonTitle
        :title="
          detailData.product &&
          detailData.product.length &&
          detailData.product[0].merchantName
        "
      />
      <div class="drug_list">
        <DrugItem
          v-for="(drugData, idx) in detailData.product"
          :key="idx"
          :data="drugData"
        >
          <template v-slot:numOrCount>
            <p class="num">x{{ drugData.count }}</p>
          </template>
        </DrugItem>
        <Cost :dis-cos="detailData.freight" :sum-money="goodsAmount" />
      </div>
    </div>

    <div class="common_info order_info">
      <CommonTitle title="订单信息" />
      <div class="info_list">
        <div class="item_container van-hairline--bottom">
          <div class="info_item">
            <span class="mr_40">订单号 : </span>
            <span>{{ detailData.mainOrderNo }}</span>
          </div>
          <div class="info_item">
            <span class="mr_30">订单时间 : </span>
            <span> {{ detailData.dateCreated }}</span>
          </div>
          <div class="info_item">
            <span class="mr_30">商品金额 : </span>
            <span>¥{{ goodsAmount }}</span>
          </div>
          <div class="info_item">
            <span class="mr_30">支付方式 : </span>
            <span>{{ detailData.paymentMethod }}</span>
          </div>
          <div class="info_item">
            <span class="mr_30">快递费用 : </span>
            <span>{{
              detailData.freight > 0
                ? `¥${Number(detailData.freight).toFixed(2)}`
                : '包邮'
            }}</span>
          </div>
          <!-- 会员卡字段 -->
          <div v-if="detailData.memberCardMethod" class="info_item">
            <span class="mr_40">会员卡 : </span>
            <span
              >-¥{{
                Number(detailData.memberCardMethod / 100).toFixed(2)
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="const_container">
        <Cost
          :dis-cos="detailData.freight"
          :sum-money="actualPayment"
          :display-express-fee="displayExpressFee"
          :money-title="moneyTitle"
        />
      </div>
    </div>
    <!-- prescriptionType类型   0纸质  1电子-->
    <!-- prescriptionPageStatus处方状态 1开具中 2查看 3解绑-->
    <!-- orderIsprescription是否含有处方药   0 否 1 是-->
    <div
      v-if="
        detailData.orderIsprescription === 1 ||
        detailData.origin === '7' ||
        detailData.origin === '8'
      "
      class="common_info"
    >
      <CommonTitle title="用药人信息" />
      <div class="info_list">
        <p class="info_item">
          {{ detailData.prescriptionName }}
        </p>
        <!-- <p
                    v-if="detailData.prescriptionType!=='1'&&detailData.prescriptionPageStatus!='3'"
                    class="info_item ">
                    已上传处方
                </p> -->

        <p
          v-if="
            detailData.prescriptionPageStatus != '3' &&
            detailData.prescriptionDisease
          "
          class="info_item add_hidden"
        >
          {{ detailData.prescriptionDisease }}
        </p>
        <p
          v-if="
            detailData.prescriptionPageStatus != '3' &&
            detailData.prescriptionAllergy
          "
          class="info_item"
        >
          {{ detailData.prescriptionAllergy }}
        </p>
      </div>
    </div>
    <div
      v-if="
        detailData.orderIsprescription === 1 ||
        detailData.origin === '7' ||
        detailData.origin === '8'
      "
      class="common_info"
    >
      <CommonTitle title="处方信息">
        <template v-slot:orderStatus>
          <div
            :class="{
              active: detailData.prescriptionPageStatus == '2',
            }"
            class="preing"
            @click="look"
          >
            <span v-if="detailData.prescriptionPageStatus === '1'">开具中</span>
            <span v-else>查看 </span>
          </div>
        </template>
      </CommonTitle>
    </div>
    <div class="common_info">
      <CommonTitle title="个人发票">
        <template v-slot:orderStatus>
          <div class="preing">
            {{ detailData.invoice ? '需要' : '不需要' }}
          </div>
        </template>
      </CommonTitle>
      <div v-if="detailData.invoice" class="info_list">
        <!-- <p class="info_item">
                    {{ detailData.prescriptionName }}
                </p> -->
        <p class="info_item">
          <span class="geren">发票抬头（个人）</span>
          {{ detailData.invoice }}
        </p>
      </div>
    </div>
    <div class="common_info">
      <CommonTitle title="购买须知" />
      <div class="purchase">
        <p>1. 选购处方药，需上传处方单图片</p>
        <p>
          2. 如需客服协助，请致电热线<a :href="'tel:' + '400 052 3990'"
            >400 052 3990</a
          >
        </p>
        <p>3. 人工客服工作时间：周一~周五09:00-18:00</p>
        <p v-if="detailData.origin !== '9'">
          4. 订单满{{ packageMailAmount }}元包邮
        </p>
      </div>
    </div>

    <div
      v-show="
        comCancelBtn(detailData) ||
        comTrackBtn(detailData) ||
        comPayBtn(detailData)
      "
      class="foot_btn"
    >
      <button
        v-show="comCancelBtn(detailData)"
        class="cancel_btn"
        @click="cancel(detailData)"
      >
        取消订单
      </button>
      <button
        v-show="comTrackBtn(detailData)"
        class="pay_btn"
        @click="goTrack(detailData)"
      >
        查看物流
      </button>
      <button
        v-show="comPayBtn(detailData)"
        class="pay_btn"
        @click="payMoney(detailData)"
      >
        立即支付
      </button>
    </div>

    <!-- 遮罩层 -->
    <div v-show="showMask" class="mask">
      <div class="bg" />
      <div class="parent">
        <div class="leftBtn" @click="reduce">
          <img :src="require('@img/order/left.png')" alt="" />
        </div>
        <div class="rightBtn" @click="add">
          <img :src="require('@img/order/right.png')" alt="" />
        </div>
        <div v-if="detailData.prescriptionUrls" class="imgNum">
          {{ index + 1 + '/' + detailData.prescriptionUrls.length }}
        </div>
        <div v-if="detailData.prescriptionUrls" class="show">
          <img :src="detailData.prescriptionUrls[index]" class="" alt="" />
        </div>
      </div>

      <div class="bottom">
        <img
          :src="require('@img/order/close.png')"
          class=""
          alt=""
          @click="closeImg"
        />
      </div>
    </div>

    <!-- 处方遮罩 -->
    <div v-show="showPrescription" class="mask">
      <div class="bg" />
      <div class="showPrescription">
        <div class="preTitle">
          处方详情
          <img
            :src="require('@img/order/pre_close.png')"
            class=""
            alt=""
            @click="closeImg"
          />
        </div>
        <div v-if="detailData.prescriptionUrls" class="preImg">
          <pdf ref="pdf" :src="detailData.prescriptionUrls[0]" />
        </div>
      </div>
    </div>

    <Dialog
      v-model="showCancelDialog"
      :confirm-button-color="'#F76D32'"
      :cancel-button-color="'#62636C'"
      confirm-button-text="确定"
      title="确认取消?"
      width="280px"
      class="tip"
      show-cancel-button
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <!-- <div class="content">
                <Field
                    v-model.trim="cancelReason"
                    rows="1"
                    autosize
                    type="textarea"
                    placeholder="请输入取消原因" />
            </div> -->
    </Dialog>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import CommonTitle from '@/components/order/CommonTitle';
import DrugItem from '@/components/order/drug-item';
import Cost from '@/components/order/cost';
import pdf from 'vue-pdf';
import api from '@/api';
import {
  CountDown,
  Dialog,
  Toast,
  // Field,
} from 'vant';
import Configs from '@/config';

import {
  checkOrderStatus,
  showCancelBtn,
  showPayBtn,
  showTrackBtn,
  dateFmt,
  handleAfter401,
} from '@/utils';

import { divide, subtract } from '@/utils/calculate';

export default {
  name: 'OrderDetail',
  components: {
    CommonTitle,
    DrugItem,
    Cost,
    pdf,
    CountDown,
    Dialog: Dialog.Component,
    // Field,
  },
  beforeRouteLeave (to, from, next) {
    const { channelEncode } = sessionStorage.sourceParam
      ? JSON.parse(sessionStorage.sourceParam)
      : {};
    if (
      to.path === '/sourceToPay'
      && Configs.txysOrigin[channelEncode] === 'TXYS'
    ) {
      next(false);
      wx.miniProgram.navigateBack();
    } else {
      next();
    }
  },
  data () {
    return {
      // isCancal: false,
      // time: 1800000, //1000*60*30=30min   //300000==5min
      time: 0,
      disCos: 20,
      sumMoney: 19.0,
      detailData: {},
      dateCreated: '',
      utils: {
        checkOrderStatus,
      },

      // 遮罩==========
      index: 0,
      images: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
      ],
      showMask: false,
      showPrescription: false,
      showTime: false,

      showCancelDialog: false,
      cancelReason: '用户自行取消订单',
      count: 0,
      statistics: 0,
      displayExpressFee: false,
      moneyTitle: '实付款',
      actualPayment: 0,
      goodsAmount: 0,
    };
  },
  computed: {
    comPayBtn () {
      return (item) => showPayBtn(item);
    },
    comCancelBtn () {
      return (item) => showCancelBtn(item);
    },
    comTrackBtn () {
      return (item) => showTrackBtn(item);
    },
    packageMailAmount () {
      if (this.defaultSource !== '') {
        return Configs.postageConfig[this.defaultSource].packageMailAmount
      } else {
        return 168;
      }
    },
    defaultSource () {
      return localStorage.getItem('defaultSource') || ''
    }
  },
  created () {
    // 判断来源
    this.getInit();
    // sessionStorage.setItem('isCancal', false)
  },
  methods: {
    getInit () {
      api.getOrderDetail({
        mainOrderNo: this.$route.params.mainOrderNo,
      }).then((res) => {
        this.$nextTick(() => {
          this.detailData = res.data;
          this.actualPayment = divide(res.data.actualPayment, 100);
          this.goodsAmount = Number(
            subtract(
              this.detailData.amount,
              this.detailData.freight,
            ),
          ).toFixed(2);
        });
        if (
          res.data.orderMixStatus === 'DRUG_WAIT_PAY'
          && this.detailData.origin > 5
        ) {
          this.changTime();
        } else {
          this.showTime = false;
        }
      });
    },
    changTime () {
      this.detailData.dateCreated = dateFmt(this.detailData.dateCreated);
      const nowTime = new Date().getTime();
      const oldTime = new Date(this.detailData.dateCreated).getTime() + 30000;
      //  let surplusTime=parseInt((nowTime-oldTime)/1000/60) ;
      if (nowTime - oldTime <= 1800000) {
        this.showTime = true;
        this.time = 1800000 + oldTime - nowTime;
      }
    },

    finish () {
      this.showTime = false;
      this.getInit();
    },

    cancel () {
      this.showCancelDialog = true;
    },
    handleConfirm () {
      this.count += 1;
      if (!this.cancelReason) {
        return Toast.fail('请填写取消原因');
      }
      const param = {
        cancelReason: this.cancelReason,
        mainOrderNo: this.$route.params.mainOrderNo,
        count: this.count,
      };
      api.getOrderCancel(param).then((res) => {
        if (res.data.code === '0') {
          this.showCancelDialog = false;
          // this.cancelReason = '';
          // this.isCancal = true
          // sessionStorage.setItem('isCancal', true)
          this.getInit();
        } else if (res.data.code === '21905') {
          Toast.fail({
            duration: 2000,
            message: res.data.message,
          });
          this.getInit();
        } else if (res.data.code === 'DO_ORDER_PAY_20008') {
          Toast.fail({
            duration: 2000,
            message: '该订单已支付',
          });
          this.getInit();
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
    handleCancel () {
      this.count = 0;
      this.showCancelDialog = false;
    },
    goTrack () {
      this.$router.push({
        name: 'log',
        params: {
          id: this.$route.params.mainOrderNo,
          expressId: this.expressId,
        },
      });
    },
    async payMoney (item) {
      this.statistics += 1;
      const param = {
        mainOrderNo: item.mainOrderNo,
        payAmount: item.amount,
        merchantCode: item.product[0].drugstoreCode,
        homeUrl: `${window.location.origin}/pysc/home`,
        detailUrl: `${window.location.origin}/pysc/detail/${item.mainOrderNo}`,
        statistics: this.statistics,
        shareCode:
          window.localStorage.getItem('shareCode') || undefined,
      };
      if (window.__wxjs_environment === 'miniprogram') {
        // 是小程序环境
        param.clientSide = 'applets';
        //派安康小程序
        const channelCode = localStorage.getItem('channelCode')
        if (channelCode === "PAK") { // 派安康来源
          param.originStatus = 'PAK';
        }
      }

      // 判断企业微信环境
      const ua = window.navigator.userAgent;
      if (ua.includes && ua.includes('wxwork')) {
        param.clientSide = 'wxwork';
      }

      if (param.shareCode) {
        param.qrCodeData = Configs.qrCodeData;
      }
      if (window.__wxjs_environment === 'miniprogram' && localStorage.getItem('jumpMpPay') && localStorage.getItem('jumpMpPay') === 'yes') {
        // 微信环境 && 跳转小程序标识是 yes
        this.toMiniProgram(param.merchantCode)
      } else {
        this.normalH5Pay(param)
      }
    },
    // 正常的H5支付流程
    normalH5Pay () {
      api.getCreatePay(param).then((res) => {
        // http://pay.medbanks-test.com/#/?orderCacheId=xxx
        if (res.data.code === '0') {
          const orderCacheId = res.data.data;
          let url = '';
          const { channelEncode } = sessionStorage.sourceParam
            ? JSON.parse(sessionStorage.sourceParam)
            : {};
          if (Configs.txysOrigin[channelEncode] === 'TXYS') {
            // 腾讯来源的, 需要去除`返回首页`按钮
            url = `${Configs.payUrl}?orderCacheId=${orderCacheId}&isHiddenBackHomeBtn=1`;
          } else {
            url = `${Configs.payUrl}?orderCacheId=${orderCacheId}`;
          }
          localStorage.removeItem('shareCode');
          window.location.href = url;
        } else if (res.data.code === '21912') {
          this.count = 0;
          // 腾讯来源的已经下过单了
          Dialog.alert({
            message: '思派云药房需要您授权登录',
            confirmButtonText: '去登录',
          }).then(() => {
            api.logout({
              token: localStorage.getItem('authentication'),
            }).then((logoutRes) => {
              if (logoutRes.data.code === '0') {
                localStorage.removeItem('authentication');
                localStorage.removeItem('userId');
                localStorage.removeItem('phone');
                handleAfter401();
              }
            });
          });
        } else {
          this.showTime = false;
          this.getInit();
          param.statistics = 0;
          this.statistics = 0;
        }
      });
    },
    // 跳转到微信小程序
    toMiniProgram (merchantCode) {
      const { amount, dateCreated, mainOrderNo, product } = this.detailData

      const query = {
        merchantCode,
        amount,
        dateCreated,
        mainOrderNo,
        product,
        token: localStorage.getItem('authentication')
      }
      wx.miniProgram.navigateTo({
        url: `/pages/payment/pay/index?query=${JSON.stringify(query)}`,
      });
    },
    look () {
      if (this.detailData.prescriptionPageStatus !== '2') {
        return;
      }
      if (this.detailData.prescriptionType === 0) {
        this.showMask = true;
      } else {
        this.showPrescription = true;
      }
    },
    closeImg () {
      if (this.detailData.prescriptionType === 0) {
        this.showMask = false;
      } else {
        this.showPrescription = false;
      }
    },
    reduce () {
      if (this.index <= 0) {
        this.index = this.detailData.prescriptionUrls.length - 1;
      } else {
        this.index -= 1;
      }
    },
    add () {
      if (this.index >= this.detailData.prescriptionUrls.length - 1) {
        this.index = 0;
      } else {
        this.index += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fix_box {
  position: fixed;
}
.box {
  width: 100%;
  background: #eee;
  .header {
    height: 80px;
    background: url('~@img/order/bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 18px;
    color: white;
    margin-bottom: 8px;
    .txt {
      font-weight: 500;
      .des {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        margin: 13px 0 2px;
      }
      .status_txt {
        height: 33px;
        line-height: 33px;
        font-size: 24px;
      }
    }
    .time {
      margin: 30px 0;
      display: flex;
      flex-direction: row;
      .time_img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .time_txt {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .addressee {
    background: #fff;
    font-size: 16px;
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 12px;
    .namephone {
      height: 22px;
      line-height: 22px;
      padding: 18px 18px 8px;
      font-weight: 600;
      color: #333;
      .name {
        margin-right: 16px;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 2px;
          background: url('~@img/phone.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 8px;
        }
      }
    }
    .address {
      display: flex;
      padding: 0 18px;
      .address_txt {
        color: #333;
        font-weight: 400;
        line-height: 22px;
        width: 287px;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
        background: url('~@img/icon_location.png') no-repeat;
        background-size: 16px 16px;
        margin-right: 8px;
      }
    }
    .group {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
    }
  }
  .card {
    background: #fff;
    margin-bottom: 8px;
    .drug_list {
      padding: 0 18px;
      .num {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
    }
  }

  .common_info {
    margin-bottom: 8px;
    background: white;
    .info_list {
      padding: 18px 18px 10px;
      .item_container {
        padding-bottom: 8px;
      }
      .info_item {
        line-height: 20px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #62636c;
        .mr_40 {
          margin-right: 44px;
        }
        .mr_30 {
          margin-right: 30px;
        }
        .geren {
          font-size: 14px;
          font-weight: 400;
          color: #90949e;
          margin-right: 18px;
        }
      }
      .add_hidden {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
    &.order_info {
      .info_list {
        padding-bottom: 0;
      }
    }
    .const_container {
      padding: 0 18px;
    }
    .preing {
      margin: 15px 0;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #62636c;
    }
    .active {
      color: #3679f0;
    }
    .purchase {
      padding: 16px 18px;
      font-size: 14px;
      font-weight: 400;
      color: #62636c;
      p {
        line-height: 22px;
      }
    }
  }
  .foot_btn {
    height: 52px;
    line-height: 52px;
    background: #fff;
    text-align: right;
    padding-right: 18px;
    .cancel_btn,
    .pay_btn {
      width: 80px;
      height: 30px;
      padding: 5px 0;
      border-radius: 15px;
      border: 1px solid #f36218;
      font-weight: 500;
      font-size: 14px;
    }
    .cancel_btn {
      font-size: 14px;
      font-weight: 500;
      color: #f36218;
      background: #fff;
    }
    .pay_btn {
      margin-left: 8px;
      color: #fff;
      background: #f36218;
    }
  }
  .mask {
    position: relative;
    .bg {
      position: fixed;
      top: 0%;
      left: 0%;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 1;
      -moz-opacity: 0.7;
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
    .parent {
      position: relative;
      .leftBtn {
        position: fixed;
        left: 0;
        top: 45%;
        width: 36px;
        height: 36px;
        // background: rgba(0, 0, 0, 0.6);
        // border-radius: 0 18px 18px 0;
        z-index: 3;
        img {
          width: 36px;
          height: 36px;
        }
      }
      .rightBtn {
        position: fixed;
        right: 0;
        top: 45%;
        width: 36px;
        height: 36px;
        // background: rgba(0, 0, 0, 0.6);
        // border-radius: 18px  0  0 18px;
        z-index: 3;
        img {
          width: 36px;
          height: 36px;
        }
      }
      .imgNum {
        font-size: 14px;
        color: #fff;
        position: fixed;
        text-align: center;
        left: 47%;
        z-index: 3;
        bottom: 15%;
      }
      .show {
        position: fixed;
        bottom: 78px;
        left: 0;
        right: 0;
        width: 300px;
        height: 549px;
        margin: 0 auto;
        z-index: 2;
        background: #fff;
        overflow-y: scroll;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 78px;
      z-index: 2;
      background: #000;
      opacity: 0.6;
      text-align: center;
      img {
        margin-top: 11px;
        width: 32px;
        height: 33px;
      }
    }
    //处方
    .showPrescription {
      z-index: 2;
      // height: 450px;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      top: 140px;
      overflow-y: scroll;
      background: #fff;
      .preTitle {
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #000;
        line-height: 25px;
        text-align: center;
        padding: 18px 18px 21px;
        img {
          float: right;
          width: 20px;
          height: 20px;
        }
      }
      .preImg {
        margin: 0 17px 0 19px;
        box-shadow: 0 2px 16px 0 rgba(60, 107, 240, 0.1);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  /deep/ .van-count-down {
    color: #fff;
  }
  /deep/ .van-dialog {
    border-radius: 4px;
  }
  // .tip {
  //     padding: 24px 22px;
  //     font-size: 16px;
  //     // font-family: PingFangSC-Regular, PingFang SC;
  //     font-weight: 400;
  //     color: #000;
  //     line-height: 25px;
  //     background: #fff;
  //     text-align: center;
  // }
}
</style>
