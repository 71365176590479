import api from '@/api';
import { readUUID, getNetworkType } from '@/utils/buryingPoint';
import { getDevice } from '@/utils';
import config from '@/config';

const { name, version } = require('../../package.json');

const mapEventIdByRoutes = {
  'sign-order-detail': {
    init: 'YFP00001V0001',
    close: 'YFP00001B0004',
  },
  guidance: {
    init: 'YFP00002V0001',
    close: 'YFP00002B0002',
    share: 'YFP00002B0001',
  },
};

//     'YFP00001V0001': {
//         eventType: 'init',
//         eventTime: new Date().getTime(),
//         requestId: '',
//         uuid: '', // dynamic
//         appId: 'general-medicine-store',
//         buttonId: 'V0001',
//         isFromShare: false,
//         latitude: '', // dynamic
//         longitude: '', // dynamic
//         // modelType: '', //???
//         networkType: '', // dynamic
//         openId: '', // dynamic
//         pageId: 'YFP00001',
//         productVersion: '', // dynamic
//         screen: '', // 分辨率
//         token: '', // dynamic
//         tp: '', // 页面停留时间
//     }

export const points = {
  YFP00001V0001: { // 签收页--初始化
    eventType: 'init',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '订单签收页浏览-V0001',
    isFromShare: false,
  },
  YFP00001B0001: { // 签收页--签收
    eventType: 'click',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '签收按钮点击-B0001',
    isFromShare: false,
  },
  YFP00001B0002: { // 签收页--提交
    eventType: 'click',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '提交按钮点击-B0002',
    isFromShare: false,
  },
  YFP00001B0003: { // 签收页--取消
    eventType: 'click',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '取消按钮点击-B0003',
    isFromShare: false,
  },
  YFP00001B0004: { // 签收页--关闭（叉号关闭、滑动关闭）
    eventType: 'close',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '叉号退出和滑动关闭-B0004',
    isFromShare: false,
  },
  YFP00002V0001: { // 用药指导--初始化
    eventType: 'init',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '用药指导页浏览-V0001',
    isFromShare: false,
  },
  YFP00002B0001: { // 用药指导--分享
    eventType: 'share',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '微信分享打开浏览-B0001',
    isFromShare: true,
  },
  YFP00002B0002: { // 用药指导--关闭（叉号关闭、滑动关闭）
    eventType: 'close',
    requestId: '/api/pi-point-service/point/collect',
    buttonId: '叉号退出和滑动关闭-B0002',
    isFromShare: false,
  },
};

const buryingPoint = {
  data() {
    localStorage.setItem('defaultSource', config.jian1BaoSource);

    return {
      current: {},
      eventId: '',
    };
  },
  mounted() {
    const { name: routeName, query: { shareForPoint } } = this.$route;
    let eventId = '';
    if (shareForPoint === '1') {
      // 页面来自分享时需要的一个eventId
      eventId = mapEventIdByRoutes[routeName].share;
    } else {
      // 页面初始化需要的一个eventId
      eventId = mapEventIdByRoutes[routeName].init;
    }
    this.eventId = eventId;
    // this.eventId = 'YFP00001V0001'

    // 窗口关闭会取消异步的ajax，这里用原生js实现同步ajax（兼容性待验证）
    const syncAjax = () => {
      eventId = mapEventIdByRoutes[routeName].close;
      this.makeBuriedPoint(eventId);

      const device = getDevice();
      const requestUrl = `${config.baseUrl}/api/pi-point-service/point/collect`;
      const params = JSON.stringify(this.current);

      if (device === 'ios') {
        const blob = new Blob([params], {
          type: 'application/json;charset=UTF-8',
        });
        navigator.sendBeacon(requestUrl, blob);
      } else {
        const xhr = new XMLHttpRequest();
        xhr.open('post', requestUrl, false);
        const data = params;

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('token', localStorage.getItem('authentication'));
        xhr.send(data);
      }
    };

    const handlerBeforePagehide = function () {
      syncAjax();
    };
    window.addEventListener('pagehide', handlerBeforePagehide);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('pagehide', handlerBeforePagehide);
    });
  },
  methods: {
    // 监听到eventId（自定义的埋点事件id）变化，就请求埋点接口
    async handleAfterSendBuriedPoint() {
      // const userId = localStorage.getItem('userId') || '';
      // const resOpenId = await api.getOpenId({
      //     source: config.jian1BaoSource,
      //     userId,
      // });
      // const { openId } = resOpenId?.data || {};
      // this.current = {
      //     ...this.current,
      //     openId,
      // };
      if (!this.eventId) return;
      this.current = {
        ...this.current,
      };

      const res = await api.sendBuriedPoint(this.current);
      if (res.code === 0 || res.code === '0') {
        this.eventId = '';
      }
    },
    // 拼凑埋点时所需要的数据结构
    makeBuriedPoint(eventId) {
      // const device = getDevice();
      // let networkType = '';
      // if (device === 'android') {
      //     networkType = navigator.connection.type;
      // }

      const { clientWidth, clientHeight } = document.documentElement;
      this.current = {
        ...points[eventId],
        eventTime: new Date().getTime(),
        uuid: readUUID(),
        appId: name,
        networkType: getNetworkType(),
        productVersion: version,
        screen: `${clientWidth}*${clientHeight}`,
        token: localStorage.getItem('authentication') || '',
      };
    },
  },
  watch: {
    eventId(n) {
      if (n) {
        console.log(n, '======================================n');
        this.makeBuriedPoint(n);
        this.handleAfterSendBuriedPoint();
      }
    },
  },
};

export default buryingPoint;
