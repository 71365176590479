<template>
    <div class="box">
        <div class="header van-hairline--bottom">
            <div class="title">
                请对本次配送服务进行评价
                <img
                    :src="require('@img/order/pre_close.png')"
                    @click="close"
                    class="closeImg">
            </div>
        </div>
        <div class="imgBox">
            <div class="imgItem">
                <!--  -->

                <img
                    :src="active == 1 ? require(`@img/detail/1_a.png`) : require(`@img/detail/1.png`)"
                    @click="handleCore(1)">
                <p :class="['mes', active == 1? 'mesActive': 'mesNormal']">
                    非常差
                </p>
            </div>
            <div class="imgItem">
                <img
                    :src="active == 2 ? require(`@img/detail/2_a.png`) : require(`@img/detail/2.png`)"
                    @click="handleCore(2)">
                <p :class="['mes', active == 2? 'mesActive': 'mesNormal']">
                    一般
                </p>
            </div>
            <div class="imgItem">
                <img
                    :src="active == 3 ? require(`@img/detail/3_a.png`) : require(`@img/detail/3.png`)"
                    @click="handleCore(3)">
                <p :class="['mes', active == 3? 'mesActive': 'mesNormal']">
                    超赞
                </p>
            </div>
        </div>
        <div class="line_box ">
            <div class="line_con van-hairline--bottom" />
        </div>
        <div class="starBox">
            <div class="startItem">
                <div class="startMes">
                    配送时效
                </div> <Rate
                    v-model="timeValue"
                    :size="24"
                    @change="handleTime"
                    class="startRate"
                    name="0"
                    color="#FF6816" />
            </div>
            <div class="startItem">
                <div class="startMes">
                    服务态度
                </div> <Rate
                    v-model="serveValue"
                    :size="24"
                    @change="handleServe"
                    class="startRate"
                    name="1"
                    color="#FF6816" />
            </div>
            <div class="startItem">
                <div class="startMes">
                    药品包装
                </div> <Rate
                    v-model="drugValue"
                    :size="24"
                    @change="handleDrug"
                    class="startRate"
                    nam="2"
                    color="#FF6816" />
            </div>
        </div>
        <div class="btnBox">
            <button
                @click="close"
                class="cancel">
                取消
            </button>
            <button
                @click="submit"
                class="confirm">
                提交
            </button>
        </div>
    </div>
</template>
<script>
import { Rate } from 'vant';

export default {
    name: 'Core',
    components: {
        Rate,
    },
    props: {
        orderCenterNo: {
            type: String,
            default: '',
        },

    },
    data() {
        return {
            timeValue: 5,
            serveValue: 5,
            drugValue: 5,
            active: 3,
            obj: {
                itemList:
                    [
                        {
                            score: '5',
                            topic: 1,
                        },
                        {
                            score: '5',
                            topic: 2,
                        },
                        {
                            score: '5',
                            topic: 3,
                        },
                    ],
                orderComment: {
                    score: 3,
                },
            },

        };
    },
    methods: {
        close() {
            this.$emit('closeDialog');
        },
        submit() {
            this.$emit('handleSubmit', this.obj);
        },
        handleCore(num) {
            this.obj.orderComment.score = num;
            this.active = num;
        },
        handleTime(event) {
            this.obj.itemList[0].score = event;
        },
        handleServe(event) {
            this.obj.itemList[1].score = event;
        },
        handleDrug(event) {
            this.obj.itemList[2].score = event;
        },
    },
};
</script>
<style lang="scss" scoped>
    .box {
        width: 100%;
        background: #fff;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px 12px 0 0;
        .header {
            height: 50px;
            line-height: 50px;
            background: #fff;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
            border-radius: 12px 12px 0 0;
            .title {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #424242;
                position: relative;
                .closeImg {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 15px;
                    right: 16px;
                }
            }
        }
        .imgBox {
            padding: 35px 56px 25px;
            display: flex;
            justify-content: space-around;
            background: #fff;
            .imgItem {
                width: 46px;
                height: 46px;
                .mes {
                    width: 42px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 20px;
                    text-align: center;
                    margin-top: 2px;
                    white-space: nowrap;
                }
                .mesNormal {
                    color: #717171;
                }
                .mesActive {
                    color: #ff6816;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .line_box {
            background: #fff;
            .line_con {
                margin: 0 57px;
                height: 25px;
            }
            // border: 1px solid #000;
        }
        .starBox {
            background: #fff;
            padding: 32px 57px 9px;
            .startItem {
                margin-bottom: 19px;
                display: flex;
                .startMes {
                    // width: 56px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 20px;
                    margin-right: 22px;
                    white-space: nowrap;
                    margin-top: 3px;
                    justify-content: center;
                    align-items: center;
                }
                .startRate {
                    margin-bottom: 0;
                    display: flex;
                    justify-content: space-around;
                    flex: 1;
                }
            }
        }
        .btnBox {
            background: #fff;
            height: 65px;
            line-height: 65px;
            display: flex;
            padding: 0 18px;
            .cancel {
                width: 160px;
                height: 44px;
                line-height: 44px;
                background: #fff;
                border-radius: 40px;
                border: 0.5px solid #f36218;
                font-size: 16px;
                font-weight: 500;
                color: #f36218;
                margin-right: 19px;
                outline: none;
            }
            .confirm {
                width: 160px;
                height: 44px;
                line-height: 44px;
                background: #f36218;
                border-radius: 40px;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                border-color: #f36218;
                outline: none;
                border: none;
            }
        }
        // /deep/ .van-rate__item {
        //     margin-right: 10px;
        // }
    }
</style>
